<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商品订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="商品名称">
                <el-input v-model="search.good_title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-select v-model="search.order_state" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="核销完成" :value="2"></el-option>
                  <el-option label="取消" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="商品名称" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="title" label="产品规格" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="!is_empty(scope.row.sku_info)">{{ scope.row.sku_info.sku_name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="order_no" label="订单状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.order_state === 1">正常</el-tag>
            <el-tag size="mini" v-if="scope.row.order_state === 2">核销完成</el-tag>
            <el-tag size="mini" v-if="scope.row.order_state === 5" type="warning">取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="store_uuid" label="适用门店" width="200">
          <template slot-scope="scope">
            <el-tag :key="item.index" v-for="item in scope.row.store_uuid_all" class="form-tag_colour-tag" type="info"
              size="mini">
              {{ item.store_name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order_create_time" label="创建日期" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="expiration_time" label="截止有效期" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="user_nick" label="用户昵称" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="total_money" label="商品价格">
          <template slot-scope="scope">
            <span>￥{{ scope.row.total_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="good_num" label="数量"> </el-table-column>
        <el-table-column prop="pay_money" label="支付金额">
          <template slot-scope="scope">
            <span>￥{{ scope.row.pay_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="退款状态/金额/单号" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.refund_state === 1">退款成功</el-tag>
            <span v-if="scope.row.refund_state === 1">/￥{{ scope.row.refund_money }}</span>
            <span v-if="scope.row.refund_state === 1">/{{ scope.row.refund_order_no }}</span>

            <el-tag size="mini" v-if="scope.row.refund_state === 2" type="warning">退款中</el-tag>
            <el-tag size="mini" v-if="scope.row.refund_state === 0" type="warning">无退款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="source_channel" label="是否团购">
          <template slot-scope="scope">
            <span v-if="scope.row.source_channel === 0">否</span>
            <span v-if="scope.row.source_channel === 1">是</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "商品订单",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() { },
  methods: {
    // 判断是否为空
    is_empty(auth) {
      return this.Tool.is_empty(auth);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        good_title: "",
        order_no: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "product.commodityorder.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
  min-height: 200px;
}
</style>
